import React, { useState } from "react";
import { motion } from "framer-motion";
import { Collapse, Divider, makeStyles } from "@material-ui/core";
import downArrow from "../../../images/v4/aiPage/icons/downArrow.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  faqSection: {
    "@media only screen and (max-width: 600px)": {
      padding: "360px 0 0 0 !important",
    },
  },
  faqsContainer: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
    cursor: "pointer",
    width: "100%",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    justufyContent: "center",
    alignItems: "center",
    gap: "32px",
    width: "80%",
    margin: "0 auto",
    textAlign: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  faqBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderBottom: "1px solid #B7B7B7",
    width: "100%",
    "&:first-child": {
      borderTop: "1px solid #B7B7B7",
      paddingTop: "15px",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      "& h3": {
        textAlign: "left",
      },
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 15px",
  },
}));
const FaqSection = ({ heading, faqs, backgroundColor, padding }) => {
  const classes = useStyles();
  const [activeAccordian, setActiveAccordian] = useState(0);
  return (
    <section
      className={classes.faqSection}
      style={{
        background: backgroundColor ? backgroundColor : "#fff",
        padding: padding ? padding : "unset",
      }}
    >
      <div className="container">
        <div className={classes.centerContainer}>
          <motion.h2
            initial={{ opacity: 1, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="font-wix-medium font-56"
          >
            {heading}
          </motion.h2>
        </div>
        <div className={classes.centerContainer}>
          <div className={classes.faqsContainer}>
            {faqs.map((faq, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className={classes.faqBox}
                onClick={() =>
                  setActiveAccordian(index === activeAccordian ? -1 : index)
                }
              >
                <div className={classes.header}>
                  <h3 className="font-20 font-wix-semibold">{faq.question}</h3>
                  <img
                    src={downArrow}
                    alt="Arrow"
                    className={clsx(
                      classes.downArrow,
                      index === activeAccordian && "active"
                    )}
                  />
                </div>
                <Collapse
                  className={classes.body}
                  in={activeAccordian === index}
                >
                  <p className="font-18 font-wix-regular font-black2">
                    {faq.answer}
                  </p>
                </Collapse>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
